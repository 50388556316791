<template>
  <v-dialog v-model="showModal" :width="1000" persistent class="custom-dialog" no-click-animation>
    <div class="dialog-container">
      <v-btn icon class="close-button" @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h1 class="dialog-title">Exportar eventos</h1>
      <p class="dialog-subtitle">Seleccione rango de fecha</p>
      <div class="input-container">
        <v-text-field v-model="startDate" label="Fecha inicio" type="date" outlined :hint="formatVisibleDate(startDate)"
          persistent-hint>
        </v-text-field>

        <v-text-field v-model="endDate" label="Fecha fin" type="date" outlined :hint="formatVisibleDate(endDate)"
          persistent-hint>
        </v-text-field>
      </div>
      <v-btn class="export-button" color="primary" @click="exportData">
        Exportar
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import * as XLSX from 'xlsx';
export default {
  props: ["showModal"],
  data() {
    const today = new Date();
    const localISODate = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];

    return {
      startDate: localISODate,
      endDate: localISODate,
      events: [],
    };
  },
  methods: {

    /**
     * Exports event data as an Excel file based on the selected date range and company.
     * Validates the date range, fetches events, formats them, and triggers the download.
    */
    async exportData() {
      if (this.validateDates()) {
        const { startDate, endDate } = this.formatData(this.startDate, this.endDate);
        const company_id = this.getSelectedCompany();

        const eventsFetchedSuccessfully = await this.getEventsForReport(company_id, startDate, endDate);

        if (eventsFetchedSuccessfully) {
          const formattedEvents = this.formatEvents(this.events);
          this.generateExcel(formattedEvents);
        } else {
          alert("Hubo un problema al obtener los eventos.");
        }
        this.closeModal();
        this.cleanDates();
      } else {
        alert("Por favor, seleccione un rango de fechas válido.");
      }
    },
    /**
     * Generates and downloads an Excel file containing event data.
     * Formats column widths dynamically based on content length and applies fixed widths to specific columns.
     * 
     * @param {Array<Object>} data - The event data to be exported.
    */
    generateExcel(data) {
      const file_name = "eventos_" + this.startDate + "_" + this.endDate + ".xlsx";
      const ws = XLSX.utils.json_to_sheet(data);

      const defaultWidth = 10;
      const fixedWidth = 15;


      const colWidth = data.reduce((acc, row) => {
        Object.keys(row).forEach((key, index) => {
          const cellValue = String(row[key]);
          acc[index] = Math.max(acc[index] || defaultWidth, cellValue.length);
        });
        return acc;
      }, []);

      colWidth[7] = fixedWidth;
      colWidth[8] = fixedWidth;

      ws['!cols'] = colWidth.map(width => ({ wch: width }));
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Eventos');
      XLSX.writeFile(wb, file_name);
    },
    /**
     * Closes the modal by updating the state to hide the events report.
    */
    closeModal() {
      this.updateShowEventsReport(false);
    },
    /**
     * Validates the selected date range.
     * Ensures both start and end dates are set and that the start date is not after the end date.
     * 
     * @returns {boolean} True if the date range is valid, otherwise false.
    */
    validateDates() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      return (this.startDate && this.endDate) && (startDate <= endDate);
    },
    /**
     * Formats the given start and end dates to include time for full-day range selection.
     * 
     * @param {string} startData - The start date in YYYY-MM-DD format.
     * @param {string} endData - The end date in YYYY-MM-DD format.
     * @returns {Object} An object containing the formatted start and end dates with time included.
    */
    formatData(startData, endData) {
      const formattedStartDate = `${startData}T00:00:00Z`;
      const formattedEndDate = `${endData}T23:59:59Z`;

      return {
        startDate: formattedStartDate,
        endDate: formattedEndDate
      };
    },
    /**
     * Fetches event data for a specific company within a given date range.
     * Sends an HTTP request and updates the event list if data is received.
     * Also checks for an authentication token in the response.
     * 
     * @param {number} company_id - The ID of the company to retrieve events for.
     * @param {string} start_date - The start date in YYYY-MM-DD format.
     * @param {string} end_date - The end date in YYYY-MM-DD format.
     * @returns {Promise<void>} Resolves when the data is fetched and processed.
    */
    async getEventsForReport(company_id, start_date, end_date) {
      try {
        const response = await this.$http.get('company_monitorings/get_events_by_range', {
          params: { company_id, start_date, end_date },
          headers: { "X-Device-ID": this.getDeviceId() },
        });

        if (response.ok) {
          if (response.body && response.body.company_events) {
            this.events = response.body.company_events;
            return true;  // Retornamos true si los eventos se obtuvieron con éxito
          } else {
            throw new Error("No se encontraron eventos.");
          }
        } else {
          const errorMessage = response.body?.message || "Error en la solicitud.";
          throw new Error(errorMessage);
        }
      } catch (error) {
        console.error("Error capturado:", error);
        return false;
      }
    },
    /**
     * Formats an array of event objects for report generation.
     * Transforms the event data to a specific structure and formats dates, states, and comments accordingly.
     * 
     * @param {Array<Object>} events - The events to be formatted.
     * @returns {Array<Object>} A new array of formatted event objects.
    */
    formatEvents(events) {
      return events.map(event => {
        const {
          event_id,
          event_type,
          location_name,
          event_time,
          event_state,
          event_comments = "",
          reviewed_by_email = "",
          user_press_buttom_alarm_or_note_created_by_user,
          event_code,
          review_date
        } = event;

        const formattedEventTime = this.formatDateInReport(event_time);
        let formattedReviewDate = "";
        if (review_date) {
          formattedReviewDate = this.formatDateInReport(review_date);
        }


        let state = "Pendiente";
        if (event_state === "VERIFIED") {
          state = "Sin solucionar";
        } else if (event_state === "REVIEWED") {
          state = "Revisado";
        }

        let reviewComment = "";
        let reviewedTypification = "";
        if (event_state === "REVIEWED") {
          const [firstComment, ...otherComments] = event_comments.split("|");
          reviewComment = firstComment;
          reviewedTypification = otherComments.join(",");
        }
        let event_message = "";
        let noteCreatedBy = "";
        let pressButtonAlarm = "";
        if (event_code === "FUSE-ALERT-SOS" || event_code === "COMMENT-EVENT") {
          const createdBy = user_press_buttom_alarm_or_note_created_by_user;
          if (event_code === "FUSE-ALERT-SOS") {
            pressButtonAlarm = createdBy;
          } else if (event_code === "COMMENT-EVENT") {
            event_message = event.event_message;
            noteCreatedBy = createdBy;
          }
        }

        return {
          "Id": event_id,
          "Tipo de evento": `${this.selectEventType(event_type)} ${this.selectEmergencyType(event.event_message)}`,
          "Locacion": location_name,
          "Estado": state,
          "Nota creada por": noteCreatedBy,
          "Descripcion nota": event_message,
          "Fecha evento": formattedEventTime,
          "Revisado por": reviewed_by_email,
          "Comentarios de revision": reviewComment,
          "Tipificacion de revision": reviewedTypification,
          "Fecha de revision": formattedReviewDate,
          "Presiono boton de alarma": pressButtonAlarm,
        };
      });
    },
    /**
     * Formats a date string into a human-readable format in Spanish (es-ES locale).
     * 
     * @param {string} dateStr - The date string to be formatted.
     * @returns {string} The formatted date string.
    */
    formatDateInReport(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleString('es-ES', {
        weekday: 'short',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
    },
    /**
     * Resets the start and end date to null.
    */
    cleanDates() {
      const today = new Date();
      const localISODate = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
        .toISOString()
        .split("T")[0];
      this.startDate = localISODate;
      this.endDate = localISODate;
    },
    /**
     * Maps an event type code to its corresponding description.
     * 
     * @param {string} eventType - The event type code.
     * @returns {string} The corresponding event description.
    */
    selectEventType(eventType) {
      const eventTypes = {
        "SOS Alert": "Botón de emergencia",
        "Custom Speaker Text": "Speaker",
        "MOTION DETECTION": "Detección de movimiento",
        "LINE CROSSING": "Cruce de línea",
        "INTRUSION": "Intrusión",
        "COMMENT EVENT": "Nota"
      };

      return eventTypes[eventType] || eventType;
    },
    /**
     * Maps an emergency type code to its corresponding description.
     * Normalizes input by trimming and converting to lowercase.
     * 
     * @param {string} emergencyType - The emergency type code.
     * @returns {string} The corresponding emergency description or an empty string if not found.
    */
    selectEmergencyType(emergencyType) {
      if (!emergencyType) return "";

      const normalizedType = emergencyType.trim().toLowerCase();

      const emergencyTypes = {
        "!sirena": "(Sirena)",
        "!panico": "(Panico)",
      };

      return emergencyTypes[normalizedType] || "";
    },
    /**
     * Formats a date to a visible, localized string in the "dd MMM yyyy" format.
     * Adjusts for the timezone offset to ensure the correct local time.
     * 
     * @param {string|Date} date - The date to be formatted.
     * @returns {string} The formatted date string in Spanish (es-ES).
    */
    formatVisibleDate(date) {
      if (!date) return "";

      const localDate = new Date(date);
      localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());

      return localDate.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }).replace(".", "");
    }



  },
};
</script>

<style scoped>
.custom-dialog .v-overlay__scrim {
  background-color: rgba(0, 0, 0, 0.6);
  /* Fondo oscuro */
}

.dialog-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  /* Esquinas redondeadas */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  /* Sombra para resaltar el modal */
}

.dialog-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.dialog-subtitle {
  font-size: 16px;
  color: #666;
}

.input-container {
  display: flex;
  gap: 15px;
}

.export-button {
  align-self: flex-end;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
}
</style>
