<template>
  <login v-if="!getIsLogin()"></login>
  <v-app v-else id="inspire">
    <alert-modal v-if="getIsInAlert()"></alert-modal>
    <loader v-if="getLoader()"></loader>
    <v-app-bar app class="header">
      <div class="header__principal">
        <v-app-bar-nav-icon @click="updateShowDrawer(!getShowDrawer())"></v-app-bar-nav-icon>
        <v-toolbar-title><app-logo class="app_logo"></app-logo></v-toolbar-title>
      </div>
      <v-btn class="header__new_comment_event" @click="updateShowCommentEventModal(true)">
        <v-icon>mdi-note-outline</v-icon>
        Crear Nota
      </v-btn>


      <div class="header__close_session" @click="closeSession()">
        <v-icon>logout</v-icon>
        <p>Salir</p>
      </div>
    </v-app-bar>
    <v-main class="monitoring">
      <div id="main-wrapper">
        <navigation v-if="getShowDrawer()"></navigation>
        <router-view></router-view>
        <comment-event-modal v-if="getShowCommentEventModal()"></comment-event-modal>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({ drawer: true }),
  mounted() {
    this.buildDeviceId()
    if (this.getUserToken() === null ||
      this.getUserToken() === '' ||
      this.getUserToken() === undefined) {
      this.$router.push({ name: 'login' })
    }
    console.log("Tokens de entorno")
    console.log(process.env.VUE_APP_JITSI_TOKEN_AUTHORIZATION)
  },
  methods: {
    closeSession() {
      this.updateUserToken(null)
      this.updateIsLogin(false)
      this.updateUserId('')
      this.updateMonitoredCompanies([])
      this.updateSelectedCompany(null)
      this.$router.push({ name: "login" })
    },
  }
}
</script>

<style lang="scss">
@import './assets/stylesheets/application.scss';

header {
  left: 0 !important;
}
</style>
